import React from "react";

const Banner = ({ ...props }) => {

  return (
    <>
      {(
        <div {...props} className="banner">
          <div className="banner-inner" style={{display:'block', backgroundColor: 'white', color: 'rgb(86, 88, 221)'}}>
            <div className="banner-container" >
              <a
                style={{ color: "rgb(86, 88, 221)" }}
                className="banner-link banner-link-green"
                href="mailto:ian@droneshiftmedia.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Banner;
