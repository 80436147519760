import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const Marion = ({
  className,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  invertMobile,
  invertDesktop,
  alignTop,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );



  const tilesClasses = classNames(
    "tiles-wrap center-content",
    
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title:
      "Marion",
    paragraph: `Marion brings her background as an accomplished television journalist, writer and video producer with 30 years of experience in domestic and international productions to every assignment.
    Her passion for cinematic storytelling is evident in her customized creative approach to all projects, editorial and corporate.
    As a producer she ensures compliance with all regulatory requirements.    
    `,
  };

  const sectionHeader2 = {
    paragraph: `Experience: Marion has created, produced and filmed over 50 full-length documentary films and many more reportages and segments, as well as educational and corporate videos.
    
    Based in Tulsa since January 2023, she is excited about telling local stories and sharing them with the rest of the world.
    
    Marion is polyglot and loves to bridge linguistic and cultural divides for her clients.`,
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className={tilesClasses} style={{paddingTop:"10%"}} >
            <Image
              src={require("./../../assets/images/HeadShotMarion.jpeg")}
              alt="Ian Duncan"
              width={"30%"}
              height={"30%"}
            />
          </div>
          <div className={tilesClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <SectionHeader data={sectionHeader2} className="center-content" />
          </div>
        </div>
      </div>
    </section>
  );
};

Marion.propTypes = propTypes;
Marion.defaultProps = defaultProps;

export default Marion;
