import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const Ian = ({
  className,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  invertMobile,
  invertDesktop,
  alignTop,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );



  const tilesClasses = classNames(
    "tiles-wrap center-content",
    
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title:
      "Drone Shift Media is operated by Ian Duncan, a FAA Part 107 certified drone pilot and creative content creationist. ",
    paragraph: `
    Ian is a technology geek with a passion for creating captivating stories with visual media. He has been producing photo, video and audio content since 2013. Whether YouTubing, podcasting, producing commercials, or writing original copy for advertisements, Ian loves the creative process. He hopes to share his humor and knack of visual storytelling to create the exciting content his clients deserve. 
    `,
  };

  const sectionHeader2 = {
    paragraph: `Experience: As a FAA Part 107 certified drone pilot and general liability insurance professional, Ian also has the technical know-how to complete the job in a safe and compliant way.
    Ian works, lives, and flies in Tulsa, Oklahoma. He’s available for any and all projects where skilled drone piloting and a creative eye are required.`,
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className={tilesClasses} style={{paddingTop:"10%"}} >
            <Image
              src={require("./../../assets/images/profile_pic.JPG")}
              alt="Ian Duncan"
              width="30%"
              height="30%"
            />
          </div>
          <div className={tilesClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <SectionHeader data={sectionHeader2} className="center-content" />
          </div>
        </div>
      </div>
    </section>
  );
};

Ian.propTypes = propTypes;
Ian.defaultProps = defaultProps;

export default Ian;
