import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import backgroundVideo from '../../assets/background.mp4';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {


  const outerClasses = classNames(
    'hero section center-content',
  );


  return (
    <section
      {...props}
      className={outerClasses}
    >
        <div >
          <div className="hero-content" >
            <video autoPlay loop muted id="video" src={backgroundVideo}
            onLoad={props.finishedloading} >
            </video>
          </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;