import React from "react";
// import sections
import Hero from "../components/sections/Hero";
import Ian from "../components/sections/Ian";
import Marion from "../components/sections/Marion";
import Descriptions from "../components/sections/FeaturesSplit";



const Home = () => {
  const [loading, setLoading] = React.useState(true)

  const finishedloading = () => {
    console.log("finished loading")
    setLoading(false)

  }


  return (
    loading? <Hero finishedloading={finishedloading()} /> :
    <>
      <Hero  />
      <Ian className="illustration-section-01"  />
      <Marion className="illustration-section-01"  />
      <Descriptions
        invertMobile
        topDivider
        imageFill
        className="illustration-section-02"
      />
    </>
  );
};

export default Home;
