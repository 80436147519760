import React from 'react';
import Image from '../../elements/Image';

const Logo = ({
  className,
  ...props
}) => {


  return (

          <Image
            src={require('./../../../assets/images/logo.png')}
            alt="Drone Shift Media"
            width={'40%'}
            height={'40%'}
             />
  );
}

export default Logo;